<template>
  <div class="m">
    <newTitle txt="Travel Tips"></newTitle>
    <div class="main">
    <!-- 轮播图 -->
    <div class="detali-box-carousel">
      <el-carousel
        :autoplay="false"
        :interval="3000"
        arrow="always"
        ref="slideCarousel"
      >
        <el-carousel-item v-for="(item, index) in strategyDetail.images" :key="index">
          <!-- {{ item }} -->
          <el-image
            fit="cover"
            class="detali-box-carousel-img"
            :src="item"
          />
        </el-carousel-item>
      </el-carousel>
    </div>

      <div class="main-detail">
        <div class="main-title">{{ strategyDetail.title }}</div>
        <!-- <div class="dtl-txt" v-html="strategyDetail.content"></div> -->
        <div
          class="dtl-txt"
          v-if="strategyDetail.content"
          v-html="strategyDetail.content"
        ></div>
        <!-- <div class="dtl-img"></div> -->
      </div>
      <!-- 标签 -->
      <div class="f-tagBox">
        <!-- <newTag :item="strategyDetail.links" :paddingBr="true"></newTag> -->
        <div class="f-tagBox-item" v-for="item in strategyDetail.links" @click="tagClick(item.value,item.type)">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductStrategyDetails } from "@/api/hfIndex";
import newTitle from "./Component_title.vue";
export default {
  components: { newTitle },
  data() {
    return {
      id: "",
      strategyDetail: [],
      lanListPam: {
        zh: "sc",
        zh_tw: "tc",
        en: "en",
        ja: "jp",
        ko: "kr",
      },
    };
  },
  activated() {
    this.id = this.$route.query.id;
    this.getProductStrategyFn(this.id);
  },
  methods: {
    tagClick (val, type) {
      // console.log("点击", val, type);
      if (!val) {
        console.log("无跳转值，忽略");
        return false;
      }
      let _this = this;
      if (type == 1) {
        if (val.indexOf("/pages") != -1) {
          // uni.navigateTo({ url: val });
        } else {
          window.open(val, "_blank");
        }
      }
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(".el-carousel__container");
      var startPointX = 0;
      var stopPointX = 0;
      //重置坐标
      var resetPoint = function () {
        startPointX = 0;
        stopPointX = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPointX = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPointX = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPointX == 0 || startPointX - stopPointX == 0) {
          resetPoint();
          return;
        }
        if (startPointX - stopPointX > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPointX - stopPointX < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },

    async getProductStrategyFn(id) {
      console.log({ id });
      let res = await getProductStrategyDetails({ id });
      this.strategyDetail = res.data.data;
      console.log(this.strategyDetail);
    },
  },
  // computed: {
  //   lanPam() {
  //     return this.lanListPam[localStorage.getItem("locale")] || "tc";
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.m {
  height: 100vh;
  max-width: 766px;
  margin: 0 auto;
}

.main {
  padding-bottom: 15px;
// 走马灯样式
.detali-box-carousel {
  height: 62.4vw;
  width: 100%;
  .detali-box-carousel-img {
    width: 100%;
    height: 100%;
  }
}
/deep/.el-carousel__container {
  // height: 322px;

  .el-carousel__item {
    // background-color: aqua;
  }
}
/deep/.el-carousel__arrow {
  //   background-color: rgba(255, 255, 255, 0.5);
  display: none;
}
/deep/.el-carousel {
  // overflow: unset;
  height: 100%;
  .el-carousel__container {
    height: 56.5333vw;
  }
  .el-carousel__indicators {
    bottom: 1.3333vw;
    .el-carousel__indicator {
      padding: 0.8vw;

      .el-carousel__button {
        width: 1.6vw;
        height: 1.6vw;
        border-radius: 50%;
        background-color: #de6460;
      }
    }
  }
}
  .main-detail {
    padding: 16px 16px 0;

    .main-title {
      font-weight: 700;
      font-size: 18px;
      color: #141414;
      margin-bottom: 20px;
    }
    /deep/.dtl-txt {
      font-weight: 400;
      font-size: 16px;
      color: #595959;
      white-space: pre-wrap;
      img{
        width: 100%;
        height: auto;
      }
    }
    .dtl-img {
      width: 343px;
      height: 257px;
      background: #f1f1f1;
      margin: 12px 0;
    }
  }

  .f-tagBox {
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px;
    .f-tagBox-item{
      font-size: 14px;
      background-color: rgb(243, 248, 255);
      color: rgb(77, 140, 245);
      border-radius: 12px;
      padding: 5px 10px;
      margin-bottom: 12px;
      margin-right: 5px;
    }
  }
}
</style>
